/* Import Team pictures */
import { Style } from './Globals'
import NavMagicContainer from './NavMagicContainer'


const Footer = () => 
  <address className='mt-6'>
      A.N.K. Tours AG<br />
      Hanspeter Niklaus<br />
      Gerberstrasse 9<br />
      4410 Liestal<br />
      061 951 24 24<br />
      <a href="mailto:info@ank-tours.ch" className='underline hover:no-underline'>info@ank-tours.ch</a>
  </address>




const Jobs = () => {

  return (
    <div>
      <NavMagicContainer />
      <div className="container m-auto text-left p-4 md:w-2/3">
      <h1 className={`${Style.Title} pt-16`}>Jobs</h1>
      <strong className={`p-4 ${Style.TextColor} text-2xl`}>Wir freuen uns auf dich!</strong>

      <div className='bg-slate-100 my-8 p-8  m-auto'>
              <h2 className='text-3xl mb-4'>Flexible Reinigungskraft (ca. 4-6 Stunden / Woche) (m/w)              </h2>
              Wir suchen per sofort oder nach Vereinbarung eine zuverlässige und flexible 
Reinigungskraft zur Reinigung von Reisecars. Garage befindet sich in Wittinsburg. 
Die Einsätze sind nach Bedarf und flexibel planbar.
              <div className="flex flex-wrap flex-col text-left justify-center">
                <div className='p-4'>
                  <h3 className='font-bold'>Ihr Job </h3>
                  Gründliche Reinigung und Pflege von Reisecars (Innenbereich)
                </div>
                <div className='p-4'>
                  <h3 className='font-bold'>Ihr Profil</h3>
                  <ul className='list-disc list-inside'>
                  <li>Erfahrung in der Reinigung von Vorteil, aber nicht zwingend</li>
                  <li>Flexibilität bei den Arbeitszeiten (unter der Woche 4 – 6 Stunden)</li>
                  <li>Sorgfältige, zuverlässige und selbständige Arbeitsweise</li>
                  <li>Wohnort in der Nähe oder gute Erreichbarkeit des Arbeitortes</li>

                  </ul>
                </div>
              </div>

              <div className='mx-auto my-4 text-sm'>
              Hast Du Interesse an dieser Tätigkeit und erfüllst unser Profil, dann melde Dich bei uns.
              <Footer />
              </div>
            </div>

      
    </div>
    </div>

  )
}

export default Jobs